.po_items_ho {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 2rem;
    padding: 1rem;
}

.po_item {
    position: relative;
    border-radius: 12px;
    overflow: hidden;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    background: var(--secondary-color);
    min-height: 350px;
}

.po_item img {
    width: 100%;
    height: 250px;
    object-fit: cover;
    transition: transform 0.3s ease;
}

.po_item .content {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.8);
    padding: 1.5rem;
    transform: translateY(100%);
    transition: transform 0.3s ease;
}

.po_item:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.po_item:hover .content {
    transform: translateY(0);
}

.po_item:hover img {
    transform: scale(1.05);
}

.po_item .content p {
    color: #fff;
    font-size: 0.9rem;
    margin-bottom: 1rem;
    opacity: 0.9;
}

.po_item .content a {
    display: inline-block;
    padding: 0.5rem 1.5rem;
    background: var(--primary-color2);
    color: #fff;
    border-radius: 25px;
    text-decoration: none;
    font-weight: 500;
    transition: background 0.3s ease;
}

.po_item .content a:hover {
    background: var(--secondary-color);
}

.po_item {
    position: relative;
    border-radius: 12px;
    overflow: hidden;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    background: var(--secondary-color);
    min-height: 350px;
}

.po_item .project-title {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.8);
    color: #fff;
    padding: 1.5rem;
    margin: 0;
    font-size: 1.1rem;
    transition: opacity 0.3s ease;
}

.po_item .content {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.8);
    padding: 1.5rem;
    transform: translateY(100%);
    transition: transform 0.3s ease;
    opacity: 0;
}

.po_item:hover .project-title {
    opacity: 0;
}

.po_item:hover .content {
    transform: translateY(0);
    opacity: 1;
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .po_items_ho {
        grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
        gap: 1.5rem;
    }
    
    .po_item {
        min-height: 300px;
    }
    
    .po_item img {
        height: 200px;
    }
}