section {
    flex: 1 0 auto;
    position: relative;
    width: 100%;
    -webkit-transition: all .5s ease-in;
    -o-transition: all .5s ease-in;
    transition: all .5s ease-in;
}

.who_am_I {
    font-family: Cinzel;
}

.has-first-color {
    color: var(--primary-color);
}

.btn-portfolio {
    background: #fff;
    border-radius: 0;
}

.btn-portfolio a {
    color: #000;
    text-decoration: none;
}

.btn-about a {
    color: var(--text-color);
    text-decoration: none;
}

.intro_sec {
    height: calc(100vh - 60px);
    min-height: 700px;
    height: 100vh;
    margin-top: -60px;
}

/* Add this overlay div after your h_bg-image div */
.blur-overlay {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 150px; /* Adjust height of blur transition */
    background: linear-gradient(
        to bottom,
        transparent,
        var(--bg-color) 100%
    );
    pointer-events: none;
    z-index: 1;
}

@media (max-width:991.98px) {
    .intro_sec {
        display: block;
        height: auto!important;
    }
}

.intro_sec .text,
.intro_sec .h_bg-image {
    width: 50%;
}

@media (max-width:991.98px) {
    .intro_sec .text,
    .intro_sec .h_bg-image {
        width: 100%;
    }
}

.intro_sec .intro {
    max-width: 450px;
    margin: 0 auto;
}

@media (max-width:991.98px) {
    .intro_sec .intro {
        max-width: 700px;
        padding-left: 20px;
        padding-right: 20px;
    }
}

.intro_sec .intro .feature .wrap-icon {
    background: 0 0!important;
    width: auto;
    height: auto;
    margin-bottom: 0;
}

.intro_sec .intro .feature .wrap-icon svg {
    color: #5cccc9;
}

.intro_sec .text h1 {
    font-size: 30px;
    margin-bottom: 50px;
    font-weight: 700;
}

.intro_sec .text h3 {
    font-size: 16px;
    font-weight: 700;
}

.intro_sec .h_bg-image {
    background-size: cover;
    background-position: center;
    min-height: 700px;
    position: relative;
}

.ac_btn {
    padding: 4px 19px;
    color: var(--secondary-color);
    position: relative;
    border: var(--secondary-color) 2px solid;
    overflow: hidden;
    transition: all 0.6s cubic-bezier(0.55, 0, 0.1, 1);
    cursor: pointer;
    border-radius: 0;
    margin-right: 20px;
}

.ac_btn a {
    text-decoration: none;
}

.ac_btn:hover {
    box-shadow: 8px 8px 0px var(--text-color), -8px -8px 0px var(--text-color);
}

.ac_btn:hover .one {
    opacity: 1;
    transform: translate3d(0px, 0px, 0px);
}

.ac_btn:hover .two {
    transform: translate3d(0px, 0px, 0px);
}

.ac_btn:hover .three {
    transform: translate3d(0px, 0px, 0px);
}

.ac_btn:hover .four {
    transform: translate3d(0px, 0px, 0px);
}

.ac_btn .ring {
    width: 100%;
    height: 100%;
    position: absolute;
    background: transparent;
    top: 0;
    left: 0;
    transform: translate3d(0px, 90px, 0px);
}

.ac_btn .one {
    background-color: #000;
    transition: all 0.3s cubic-bezier(0.55, 0, 0.1, 1);
    z-index: -3;
    z-index: -4;
}

.ac_btn .two {
    background-color: var(--primary-color);
    transition: all 0.5s cubic-bezier(0.55, 0, 0.1, 1);
    z-index: -3;
}

.ac_btn .three {
    background-color: var(--secondary-color);
    z-index: -2;
    transition: all 0.7s cubic-bezier(0.55, 0, 0.1, 1);
    z-index: -3;
}

#button_p {
    background: var(--secondary-color);
    color: #fff;
}

#button_h:hover {
    color: var(--primary-color)
}

.resume-section {
    text-align: center;
    margin-top: 1rem;
}

.job-seeking-text {
    color: var(--text-color);
    margin-bottom: 1rem;
    font-size: 1.1rem;
}

.resume-btn {
    display: inline-block;
    margin-top: 0.5rem;
}

.intro_sec .h_bg-image .block {
    bottom: 50vh;
    max-width: 220px;
    padding: 30px;
    left: -110px;
    position: absolute;
    width: 250px;
    height: 220px;
    border-radius: 100%;
    /* mix-blend-mode: multiply; */
    background-color: #2f514e;
    z-index: 9999999999;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}

@media (max-width:991.98px) {
    .intro_sec .h_bg-image {
        height: 600px;
        min-height: 75vh;
        margin-bottom: 30px;
    }
    .intro_sec .h_bg-image .block {
        top: unset;
        bottom: 0;
        width: 100%;
        margin: 0;
        left: 50%;
        transform: translate(-50%, 50%);
    }
}

.intro_sec .h_bg-image .block .wrap-icon {
    font-size: 40px;
    color: var(--text-color);
    margin-bottom: 10px;
    display: block;
}

.intro_sec .h_bg-image .block h3 {
    font-size: 14px;
    color: var(--text-color);
    letter-spacing: .1rem;
    font-weight: 700;
    margin-bottom: 15px;
}

.intro_sec .h_bg-image .block p {
    font-size: 14px;
    line-height: 1.3;
    color: var(--text-color)
}

.intro_sec .h_bg-image {
    filter: saturate(0.5);
}

/* Add to your existing CSS */
.achievements-section, 
.featured-projects {
    max-width: 1200px;
    margin: 6rem auto;
    padding: 0 2rem;
}

.section-title {
    font-size: 3rem;
    margin-bottom: 3rem;
    position: relative;
}

.section-title::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: -10px;
    width: 60px;
    height: 4px;
    background: var(--primary-color);
}

.achievements-section {
    max-width: 1200px;
    margin: 6rem auto;
    padding: 0 2rem;
}

.achievements-slider {
    margin: 0 -15px;
}

.achievement-slide {
    padding: 15px;
}

.achievement-card {
    background: rgba(255, 255, 255, 0.05);
    backdrop-filter: blur(10px);
    border: 1px solid rgba(0, 0, 0, 0.1);
    padding: 2rem;
    text-align: center;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    height: 100%;
}

.achievement-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15);
}

.achievement-card img {
    width: 120px;
    height: 120px;
    margin-bottom: 1.5rem;
    object-fit: contain;
}

.achievement-card h3 {
    font-size: 1.2rem;
    margin-bottom: 0.5rem;
    color: var(--text-color);
}

.achievement-card p {
    color: var(--text-color-2);
    margin-bottom: 0.5rem;
}

.achievement-card a {
    display: inline-block;
    margin-top: 1rem;
    padding: 0.5rem 1.5rem;
    background: var(--secondary-color);
    color: #fff;
    text-decoration: none;
    transition: background 0.3s ease;
}


/* Custom styles for slider arrows and dots */
.slick-prev,
.slick-next {
    z-index: 1;
    width: 40px;
    height: 40px;
}

.slick-prev {
    left: -40px;
}

.slick-next {
    right: -40px;
}

.slick-dots li button:before {
    color: var(--text-color1);
}

.slick-dots li.slick-active button:before {
    color: var(--primary-color2);
}

/* Projects Grid */
.projects-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
    gap: 2rem;
}

.project-card {
    background: var(--primary-color2);
    border-radius: 16px;
    overflow: hidden;
    transition: transform 0.3s ease;
}

.project-card:hover {
    transform: translateY(-5px);
}

.project-card img {
    width: 100%;
    height: 200px;
    object-fit: cover;
}

.project-content {
    padding: 1.5rem;
}

.project-content h3 {
    font-size: 1.2rem;
    margin-bottom: 1rem;
    color: #fff;
}

.project-content p {
    color: #fff;
    margin-bottom: 1.5rem;
}

.project-content a {
    display: inline-block;
    padding: 0.5rem 1.5rem;
    background: var(--secondary-color);
    color: #fff;
    border-radius: 25px;
    text-decoration: none;
    transition: background 0.3s ease;
}

.view-more {
    text-align: center;
    margin-top: 3rem;
}

/* Blog Section Styles */
.featured-blogs {
    max-width: 1200px;
    margin: 6rem auto;
    padding: 0 2rem;
}

.blogs-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2rem;
    margin-top: 3rem;
}

.blog-card {
    background: var(--primary-color2);
    border-radius: 16px;
    overflow: hidden;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.blog-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}

.blog-card img {
    width: 100%;
    height: 200px;
    object-fit: cover;
    transition: transform 0.3s ease;
}

.blog-card:hover img {
    transform: scale(1.05);
}

.blog-content {
    padding: 1.5rem;
}

.blog-category {
    display: inline-block;
    padding: 0.3rem 1rem;
    background: var(--primary-color);
    color: #fff;
    border-radius: 20px;
    font-size: 0.8rem;
    margin-bottom: 1rem;
}

.blog-content h3 {
    font-size: 1.2rem;
    margin-bottom: 1rem;
    color: #fff;
}

.blog-content p {
    color: #fff;
    font-size: 0.9rem;
    margin-bottom: 1rem;
    line-height: 1.6;
}

.blog-meta {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1.5rem;
    font-size: 0.8rem;
    color: #fff;
}

.blog-content a {
    display: inline-block;
    padding: 0.5rem 1.5rem;
    background: var(--primary-color);
    color: #fff;
    border-radius: 25px;
    text-decoration: none;
    transition: background 0.3s ease;
}

.blog-content a:hover {
    background: var(--accent-color);
}

.view-more {
    text-align: center;
    margin-top: 3rem;
}

.view-more .ac_btn {
    display: inline-block;
    padding: 0.8rem 2rem;
    background: transparent;
    border: 2px solid var(--primary-color);
    color: var(--text-color);
    border-radius: 0px;
    text-decoration: none;
    transition: all 0.3s ease;
    position: relative;
    overflow: hidden;
}

.view-more .ac_btn:hover {
    background: var(--primary-color);
    color: #fff;
}

/* Animation for blog cards */
@keyframes fadeInUp {
    from {
        opacity: 0;
        transform: translateY(20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.blog-card {
    animation: fadeInUp 0.6s ease forwards;
}

/* Skills Carousel */
.skills-carousel {
    padding: 40px 0;
    margin-top: 20px;
    color: white;
    text-align: center;
}

.carousel-wrapper {
    max-width: 100%;
    overflow: hidden;
}

.skill-slide {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
}

/* Normalize logo sizes */
.skill-logo {
    width: auto;
    height: auto;
    max-width: 200px;
    max-height: 160px;
    object-fit: contain;
    padding: 10px;
    border-radius: 8px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

/* Ensure logos scale up or down */
.skill-logo:hover {
    transform: scale(1.4);
}

/* Responsive Design */
@media (max-width: 1024px) {
    .skill-logo {
        max-width: 200px;
        max-height: 200px;
    }
}

@media (max-width: 768px) {
    .skill-logo {
        max-width: 100px;
        max-height: 100px;
    }
}

/* Responsive Design */
@media (max-width: 1024px) {
    .blogs-grid {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (max-width: 768px) {
    .blogs-grid {
        grid-template-columns: 1fr;
    }

    .blog-card img {
        height: 180px;
    }
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .section-title {
        font-size: 2.5rem;
    }

    .achievements-grid {
        gap: 1.5rem;
    }

    .achievement-card {
        width: 100%;
    }

    .projects-grid {
        grid-template-columns: 1fr;
    }
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .slick-prev {
        left: -20px;
    }
    
    .slick-next {
        right: -20px;
    }
}