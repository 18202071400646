:root {
    /* Modern Dark Theme Defaults */
    --bg-color: #0f172a; /* Rich dark blue background */
    --primary-color: #06b6d4; /* Vibrant cyan */
    --primary-color2: #3b82f6; /* Bright blue */
    --secondary-color: #8b5cf6; /* Vibrant purple */
    --menu-color1: #f472b6; /* Pink */
    --pastel-purple: #966FD6;
    --text-color1: #f1f5f9; /* Crisp white */
    --text-color: #f1f5f9; /* Crisp white */
    --text-color-2: #e2e8f0; /* Soft white */
    --text-color-3: #60a5fa; /* Light blue */
    --text-color4: #000; /* Dark blue */
    --overlay-color: rgba(15, 23, 42, 0.8); /* Transparent dark blue */
    
    /* Gradient Colors */
    --gradient-1: linear-gradient(45deg, #06b6d4, #3b82f6);
    --gradient-2: linear-gradient(135deg, #8b5cf6, #f472b6);
    
    /* Shadow */
    --box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
}

[data-theme="light"] {
    /* Modern Light Theme */
    --bg-color: #ffffff; /* Pure white */
    --primary-color: #0ea5e9; /* Sky blue */
    --primary-color2: #6366f1; /* Indigo */
    --secondary-color: #7a00ed; /* Purple */
    --menu-color1: #ec4899; /* Pink */
    --pastel-purple: #c098ff;
    --text-color: #0f172a; /* Dark blue */
    --text-color-2: #1e293b; /* Slate */
    --text-color-3: #3b82f6; /* Blue */
    --text-color-4: #fff; /* Dark blue */
    --overlay-color: rgba(255, 255, 255, 0.9);
    
    /* Light Theme Gradients */
    --gradient-1: linear-gradient(45deg, #0ea5e9, #6366f1);
    --gradient-2: linear-gradient(135deg, #a855f7, #ec4899);
}

html,
body {
    height: 100%;
}

body {
    margin: 0;
    height: 100%;
    overflow-x: hidden;
    overflow-y: visible;
    background-color: var(--bg-color);
    color: var(--text-color);
    font-family: 'Raleway', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    padding-top: 60px;
    border-left: 10px solid var(--primary-color);
    border-right: 10px solid var(--primary-color);
}

ul {
    list-style: none;
}

h1, h2, h3, h4, h5, h6 {
    font-family: Marcellus;
}

a, a:hover {
    color: var(--text-color);
}

p {
    word-break: break-word;
    hyphens: auto;
}

.ovhidden {
    overflow: hidden;
}

.text_2, .text_2:hover {
    color: var(--text-color-2);
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.cursor__dot div {
    z-index: 999999 !important;
}

.cursor__dot div:last-child {
    background-color: var(--primary-color2) !important;
}

.cursor__dot div:first-child {
    filter: invert(1);
    background-color: var(--overlay-color) !important;
}

.color_pr {
    color: var(--primary-color) !important;
}

.color_sec {
    color: var(--secondary-color);
}