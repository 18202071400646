/* About Section */
.section-title {
    font-size: 2.5rem;
    margin-bottom: 2rem;
    position: relative;
    padding-bottom: 1rem;
}

.section-title::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 50px;
    height: 3px;
    background: var(--primary-color);
}

/* Timeline Section */
.timeline {
    position: relative;
    max-width: 1200px;
    margin: 0 auto;
}

.timeline::after {
    content: '';
    position: absolute;
    width: 2px;
    background: var(--primary-color);
    top: 0;
    bottom: 0;
    left: 50%;
    margin-left: -1px;
}

.timeline-item {
    padding: 10px 40px;
    position: relative;
    width: 50%;
    cursor: pointer;
}

.timeline-date {
    position: absolute;
    width: max-content;
    padding: 8px 16px;
    background: var(--primary-color);
    color: #fff;
    border-radius: 4px;
    top: 15px;
}

.timeline-item::after {
    content: '';
    position: absolute;
    width: 20px;
    height: 20px;
    background: var(--primary-color);
    border-radius: 50%;
    top: 15px;
}

.timeline-item.left {
    left: 0;
}

.timeline-item.right {
    left: 50%;
}

.timeline-item.left::after {
    right: -10px;
}

.timeline-item.right::after {
    left: -10px;
}

.timeline-item.left .timeline-date {
    left: calc(100% + 50px);  /* Push date to the right of the content */
}

.timeline-item.right .timeline-date {
    right: calc(100% + 50px);  /* Push date to the left of the content */
}

.timeline-content {
    padding: 20px;
    background: var(--secondary-color);
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0,0,0,0.1);
    color: #fff;
}

.timeline-header {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.timeline-header h3 {
    color: #fff;
    margin: 0;
}

.timeline-header h4 {
    color: #fff;
    margin: 0;
}

.timeline-details {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease-out;
    color: #fff;
}

.timeline-details p {
    color: #fff;
    margin: 10px 0;
}

.timeline-item.expanded .timeline-details {
    max-height: 500px;
    transition: max-height 0.5s ease-in;
}

.expand-btn {
    background: none;
    border: none;
    color: #fff;
    cursor: pointer;
    padding: 5px;
    transition: transform 0.3s ease;
}

.timeline-item.expanded .expand-btn {
    transform: rotate(180deg);
}

/* Skills Section */
.skills-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 1.5rem;
}

.skill-item {
    padding: 1rem;
}

.skill-bar {
    height: 4px;
    background: var(--secondary-color);
    border-radius: 2px;
    overflow: hidden;
    margin-top: 0.5rem;
}

.skill-progress {
    height: 100%;
    background: var(--primary-color);
    transition: width 1s ease-in-out;
}

/* Responsive Design */
@media screen and (max-width: 768px) {
    .timeline::after {
        left: 31px;
    }

    .timeline-item {
        width: 100%;
        padding-left: 70px;
        padding-right: 25px;
    }

    .timeline-item.right {
        left: 0;
    }

    .timeline-item.left::after,
    .timeline-item.right::after {
        left: 21px;
    }

    .timeline-date {
        position: relative;
        top: 0;
        left: 0 !important;
        right: 0 !important;
        margin-bottom: 10px;
        width: auto;
    }
}