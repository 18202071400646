.blog-container {
    padding: 2rem 0;
  }
  
  .blog-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 2rem;
    padding: 1rem;
  }
  
  .blog-card {
    background: var(--pastel-purple);
    border-radius: 12px;
    overflow: hidden;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .blog-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }
  
  .blog-card-image {
    position: relative;
    height: 200px;
    overflow: hidden;
  }
  
  .blog-card-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.3s ease;
  }
  
  .blog-card:hover .blog-card-image img {
    transform: scale(1.05);
  }
  
  .blog-category {
    position: absolute;
    top: 1rem;
    left: 1rem;
    background: var(--primary-color2);
    color: white;
    padding: 0.25rem 0.75rem;
    border-radius: 20px;
    font-size: 0.8rem;
  }
  
  .blog-card-content {
    padding: 1.5rem;
  }
  
  .blog-meta {
    display: flex;
    justify-content: space-between;
    color: #fff;
    font-size: 0.9rem;
    margin-bottom: 1rem;
  }
  
  .blog-title {
    font-size: 1.25rem;
    margin-bottom: 1rem;
    color: #000;
    font-weight: 600;
  }
  
  .blog-summary {
    color: #000;
    font-size: 0.95rem;
    line-height: 1.5;
    margin-bottom: 1.5rem;
  }
  
  .blog-read-more {
    display: inline-block;
    color: #000;
    text-decoration: none;
    font-weight: 500;
    position: relative;
  }
  
  .blog-read-more::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 2px;
    bottom: -2px;
    left: 0;
    background-color: #fff;
    transform: scaleX(0);
    transition: transform 0.3s ease;
  }
  
  .blog-read-more:hover::after {
    transform: scaleX(1);
  }
  
  /* Responsive Adjustments */
  @media (max-width: 768px) {
    .blog-grid {
      grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
      gap: 1.5rem;
    }
  
    .blog-card-image {
      height: 180px;
    }
  
    .blog-title {
      font-size: 1.1rem;
    }
  
    .blog-summary {
      font-size: 0.9rem;
    }
  }
  